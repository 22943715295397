<template>
  <h1>User not authorized</h1>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({});
</script>

<style scoped>

</style>
